<template>
	<div data-route>
		<page-header
			heading="Lesson details"
		/>
		<div data-element="main">
			<lesson-edit
				entryRoute="admin"
			/>
		</div>
	</div>
</template>

<script>

	import PageHeader   from '@/components/ui/PageHeader';
	import LessonEdit   from '@/components/lessons/LessonEdit';
	import routeParams  from '@/mixins/routeParams';

	export default {
		metaInfo: {
			title: 'Lesson details'
		},
		components: {
			PageHeader,
			LessonEdit
		},
		mixins: [routeParams],
		data: () => ({
			lesson: {
				title:            '',
				description:      '',
				introduction:     ''
			}
		}),
		computed: {
			getCourseRoute () {
				if (!this.getCourseId) {
					return false;
				}
				return `/admin/courses/${this.getCourseId}`;
			},
			getLessonRoute () {
				if (!this.getLessonId) {
					return false;
				}
				return `${this.getCourseRoute}/lessons/`;
			},
			getBreadcrumbRoute () {
				return this.getLessonRoute;
			}
		}
	};
</script>
